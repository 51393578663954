

























































































































































































































































































































































































































































































































































































































































































































































































































import TopBar from "@/components/common/TopBar.vue";
import Widget from "@/components/common/Widget.vue";
import StaticTable from "@/components/table/StaticTable.vue";
import Vue from "vue";
import Component from "vue-class-component";
import store from "../../store";
import { Watch } from "vue-property-decorator";
import {
  germanZahl,
  getRealNum,
  callPlan,
  getGermanNum,
  callCalc,
} from "../../helper/utils";
import { Months, Signs, PlanRules } from "../../consts";
import { chartVerticalGroupBar } from "../../helper/charts";
import api from "../../api";
import lodash from "lodash";
import { Route } from "vue-router";

declare const $: any;

@Component({ components: { TopBar, Widget, StaticTable } })
export default class Plan extends Vue {
  signs: any;
  germanZahl: any;
  months: any;
  selectedType: any = "real";
  planYears: any = [];
  selectedYear: any = 2015;
  changableData: any = null;
  resetType: any = 0;
  resetRow: any = null;
  isGetDataCalled = false;
  hash = "";
  messageStatus = 0;
  topBarYear = null;

  constructor() {
    super();
    this.signs = Signs;
    this.germanZahl = germanZahl;
    this.months = Months;
    for (let i = 2015; i <= 2030; i++) {
      this.planYears.push(i);
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(route: Route) {
    this.hash = route.hash;
  }

  mounted() {
    this.changableData = lodash.cloneDeep(this.pageData.planData);

    if (this.changableData) {
      this.drawCharts();
    } else {
      this.loadPlan();
    }
  }

  get pageData() {
    return {
      clients: store.getters.clients,
      client: store.getters.selectedClient,
      planData: store.getters.planData,
      baseData: store.getters.planBaseData,
    };
  }

  get years() {
    return store.getters.years;
  }

  get currentCalcs() {
    return store.getters.currentCalcs;
  }

  get tableHeader() {
    let head: any = [
      { title: "Bereich", class: "pl-3" },
      { title: "Basiswerte" },
      { title: "Summe Plan" },
      { title: "Veränderung" },
    ];
    Months.forEach((m) => {
      head.push({ title: m });
    });
    head.push({ title: "Reset" });
    return head;
  }

  get topBarData() {
    return {
      year: store.getters.planYear,
      client: store.getters.selectedClient,
    };
  }

  @Watch("currentCalcs")
  loadPlan() {
    if (!store.getters.isPlanCalled && this.currentCalcs != null) {
      callPlan();
    }
  }

  @Watch("topBarData")
  onTopBarDataChange() {
    if (this.topBarData.year && this.topBarData.client) {
      if (store.getters.isPlanSaved) {
        this.topBarYear = store.getters.planYear;
        if (!store.getters.isCalcCalled) {
          callCalc();
        }
      } else {
        $("#plan_reminder_dialog").modal();
      }
    }
  }

  @Watch("pageData")
  onPlanDataChange() {
    if (this.pageData.planData) {
      this.changableData = lodash.cloneDeep(this.pageData.planData);
      this.drawCharts();
      if (this.isGetDataCalled) {
        this.savePlanData();
      }
    }
  }

  revertYear() {
    if (this.topBarYear) {
      store.dispatch("updatePlanYear", this.topBarYear);
      localStorage.setItem("planYear", this.topBarYear || "");
    }
  }

  reloadWithoutSaving() {
    callPlan();
    callCalc();
    store.dispatch("updateIsPlanSaved", true);
  }

  drawCharts() {
    let d00: any = [],
      d01: any = [],
      d10: any = [],
      d11: any = [],
      d20: any = [],
      d21: any = [],
      d30: any = [],
      d31: any = [];
    let legend = this.changableData.legend;
    for (var i = 0; i < legend.length; i++) {
      for (var month = 1; month < 13; month++) {
        let t1 = this.changableData[month];
        let t2 = this.pageData.planData[month];
        if (legend[i][0] == "1020") {
          d00[month < 10 ? "0" + month + ".20" : month + ".20"] =
            t1[legend[i][0]];
          d01[month < 10 ? "0" + month + ".20" : month + ".20"] =
            t2[legend[i][0]];
        } else if (legend[i][0] == "1280") {
          d10[month < 10 ? "0" + month + ".20" : month + ".20"] =
            t1[legend[i][0]];
          d11[month < 10 ? "0" + month + ".20" : month + ".20"] =
            t2[legend[i][0]];
        } else if (legend[i][0] == "1300") {
          d20[month < 10 ? "0" + month + ".20" : month + ".20"] =
            t1[legend[i][0]];
          d21[month < 10 ? "0" + month + ".20" : month + ".20"] =
            t2[legend[i][0]];
        } else if (legend[i][0] == "1380") {
          d30[month < 10 ? "0" + month + ".20" : month + ".20"] =
            t1[legend[i][0]];
          d31[month < 10 ? "0" + month + ".20" : month + ".20"] =
            t2[legend[i][0]];
        }
      }
    }

    chartVerticalGroupBar(d01, d00, "1", "2", "chart_plan_umsatz");
    chartVerticalGroupBar(d11, d10, "1", "2", "chart_plan_gesamtkosten");
    chartVerticalGroupBar(d21, d20, "1", "2", "chart_plan_bwa");
    chartVerticalGroupBar(d31, d30, "1", "2", "chart_plan_ebit");
  }

  getData() {
    this.isGetDataCalled = true;
    if (this.selectedType === "plan") {
      callPlan(this.selectedYear, true);
    } else {
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      api
        .getBwaData(this.pageData.client, this.selectedYear)
        .then((res: any) => {
          store.dispatch("updatePlanData", res.data[0]);
          store.dispatch("updatePlanBaseData", res.data[0]);
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updatePlanData", null);
          store.dispatch("updatePlanBaseData", null);
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }

  setResetValue(type: any) {
    this.resetType = type;
  }

  reset() {
    store.dispatch("updateIsPlanSaved", false);
    this.messageStatus = 0;
    if (this.resetRow) {
      if (this.resetType === 0) {
        for (var l = 1; l < 13; l++) {
          this.changableData[l][this.resetRow] = 0;
          if (this.pageData.baseData) {
            this.pageData.baseData.total[this.resetRow] = 0;
          }
          this.changeTableDataByRule(l);
        }
      } else if (this.resetType === 1) {
        for (var m = 1; m < 13; m++) {
          this.changableData[m][this.resetRow] = this.pageData.planData[m][
            this.resetRow
          ];
          this.changeTableDataByRule(m);
        }
      } else {
        for (var n = 1; n < 13; n++) {
          this.changableData[n][this.resetRow] = this.pageData.baseData[n][
            this.resetRow
          ];
          this.changeTableDataByRule(n);
        }
      }
      this.resetRow = null;
    } else {
      if (this.resetType === 0) {
        for (var i = 1; i < 13; i++) {
          for (var j = 0; j < this.changableData.legend.length; j++) {
            this.changableData[i][this.changableData.legend[j][0]] = 0;
            if (this.pageData.baseData) {
              this.pageData.baseData.total[this.changableData.legend[j][0]] = 0;
            }
          }
        }
        for (var k = 0; k < this.changableData.legend.length; k++) {
          this.changableData["total"][this.changableData.legend[k][0]] = 0;
        }
      } else if (this.resetType === 1) {
        callPlan(this.topBarData.year[0]);
      } else {
        this.changableData = lodash.cloneDeep(this.pageData.baseData);
      }
    }
    this.drawCharts();
  }

  savePlanData() {
    for (let j = 0; j < this.changableData.legend.length; j++) {
      let val = 0;
      for (let i = 1; i < 13; i++) {
        val += this.changableData[i][this.changableData.legend[j][0]];
      }
      this.changableData["total"][this.changableData.legend[j][0]] = val;
    }
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    const year = this.topBarYear || store.getters.planYear;
    api
      .savePlanData(this.pageData.client, [year], {
        version: store.getters.planVersion + 1,
        plan_month: this.changableData,
        baseData: this.pageData.baseData,
      })
      .then((res) => {
        if (!store.getters.isPlanSaved) {
          this.reloadWithoutSaving();
        }
        this.topBarYear = null;
        this.messageStatus = 1;
        store.dispatch("updateLoading", store.state.loading - 1);
        if (!this.isGetDataCalled) {
          callPlan();
        } else {
          this.isGetDataCalled = false;
        }
      })
      .catch((err) => {
        if (!store.getters.isPlanSaved) {
          this.reloadWithoutSaving();
        }
        this.topBarYear = null;
        this.messageStatus = -1;
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  changeVal(event: any, id: any, month: any) {
    store.dispatch("updateIsPlanSaved", false);
    this.messageStatus = 0;
    this.changableData[month + 1][id] = parseFloat(
      getRealNum(event.target.value)
    );
    this.changeTableDataByRule(month + 1);
    this.drawCharts();
  }

  checkVal(event: any) {
    if (
      event.which != 44 &&
      event.which != 8 &&
      event.which != 13 &&
      event.which != 0 &&
      (event.which < 46 || event.which > 57)
    ) {
      event.preventDefault();
    }
  }

  checkPercent(event: any) {
    if (
      event.which != 37 &&
      event.which != 45 &&
      event.which != 8 &&
      event.which != 13 &&
      event.which != 0 &&
      (event.which < 46 || event.which > 57)
    ) {
      event.preventDefault();
    }
  }

  changePercent(event: any, id: any) {
    store.dispatch("updateIsPlanSaved", false);
    const percent =
      ((((this.pageData.baseData ? this.pageData.baseData.total[id] : 1) *
        (parseFloat(getRealNum(event.target.value.split("%")[0])) + 100)) /
        100) *
        100) /
        this.changableData["total"][id] -
      100;
    this.messageStatus = 0;
    for (let i = 1; i < 13; i++) {
      this.changableData[i][id] =
        this.pageData.planData[i][id] +
        (this.pageData.planData[i][id] * percent) / 100;
      this.changeTableDataByRule(i);
    }
    this.drawCharts();
  }

  getTotal(id: any) {
    let val = 0;
    for (let i = 1; i < 13; i++) {
      val += this.changableData[i][id];
    }
    return germanZahl(val);
  }

  getTotalVal(id: any) {
    let val = 0;
    for (let i = 1; i < 13; i++) {
      val += this.changableData[i][id];
    }
    return val;
  }

  getPercent(id: any) {
    if (
      this.changableData["total"][id] == 0 ||
      !this.pageData.baseData ||
      this.pageData.baseData.total[id] == 0
    ) {
      return "0,0%";
    } else {
      return (
        getGermanNum(
          (
            (this.getTotalVal(id) * 100) / this.pageData.baseData.total[id] -
            100
          ).toFixed(1)
        ) + "%"
      );
    }
  }

  changeTotal(event: any, id: any) {
    store.dispatch("updateIsPlanSaved", false);
    this.messageStatus = 0;
    let val = 0;
    for (let i = 1; i < 13; i++) {
      val += this.changableData[i][id];
    }
    let percent = 0;
    if (val != 0) {
      percent = parseFloat(getRealNum(event.target.value)) / val;
      for (let i = 1; i < 13; i++) {
        this.changableData[i][id] *= percent;
        this.changeTableDataByRule(i);
      }
    } else {
      for (let i = 1; i < 13; i++) {
        this.changableData[i][id] =
          parseFloat(getRealNum(event.target.value)) / 12;
        this.changeTableDataByRule(i);
      }
    }
    this.drawCharts();
  }

  changeTableDataByRule(month: any) {
    PlanRules.forEach((rule) => {
      let val = 0;
      rule.plus.forEach((p) => {
        if (typeof this.changableData[month][p] !== "undefined") {
          val += this.changableData[month][p];
        }
      });
      if (rule.minus) {
        rule.minus.forEach((m) => {
          if (typeof this.changableData[month][m] !== "undefined") {
            val -= this.changableData[month][m];
          }
        });
      }
      if (typeof this.changableData[month][rule.id] !== "undefined") {
        this.changableData[month][rule.id] = val;
      }
    });
  }

  destroyed() {
    const year = store.getters.selectedYear[0];
    store.dispatch("updatePlanYear", year);
    year && localStorage.setItem("planYear", year);

    if (store.getters.isPlanSaved) {
      callPlan(year);
    }
  }
}
