




































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Signs, Months, BwaSummaryTable } from "@/consts";
import { getRouter } from "../../router";
import api from "../../api";
import store from "../../store";
import {
  getGermanNum,
  germanZahl,
  getFormattedNum,
  getFormattedGermanNum,
} from "../../helper/utils";

@Component
export default class StaticTable extends Vue {
  @Prop() data!: any;
  @Prop() id!: string;
  @Prop() tableClass!: string;
  signs: any;
  months: any;
  germanZahl: any;
  getFormattedNum: any;

  constructor() {
    super();
    this.signs = Signs;
    this.months = Months;
    this.germanZahl = germanZahl;
    this.getFormattedNum = getFormattedNum;
    this.test();
  }

  test() {
    store.watch(
      (getter) => {
        let date = new Date();
        if (
          store.getters.selectedYear &&
          date.getFullYear() == store.getters.selectedYear[0]
        ) {
          let maxData = date.getMonth() + 1;
          let prev =
            store.getters.previousCalcs && store.getters.previousCalcs.BWA;
          let current =
            store.getters.currentCalcs && store.getters.currentCalcs.BWA;
          if (prev) {
            prev.legend.map((eachPrevious: any) => {
              let totalVal = 0;
              for (let i = 1; i <= maxData; i++) {
                totalVal += prev[i][eachPrevious[0]];
              }
              prev.total[eachPrevious[0]] = totalVal;
            });
          }
        }
      },
      (getters) => {}
    );
  }

  get storeData() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient,
    };
  }

  getTotal(data: any) {
    let total = 0;
    data.forEach((d: any) => {
      if (this.id === "ums_table") {
        total += d[2];
      } else if (this.id === "kunden_table" || this.id === "ek_table") {
        total += d[1];
      }
    });
    return total;
  }

  getKostenArr() {
    let curArr: any = [];
    let prevArr: any = [];
    if (this.data.body) {
      this.data.body.current.forEach((d: any, i: number) => {
        curArr.push([d[0], d[1], d[2], true]);
        if (this.data.body.prev) {
          prevArr.push([
            this.data.body.prev[i][0],
            this.data.body.prev[i][1],
            this.data.body.prev[i][2],
            true,
          ]);
        }
        if (d[3]) {
          let prevD: any;
          if (this.data.body.prev) {
            prevD = this.data.body.prev[i][3];
          }
          d[3].forEach((dd: any, j: number) => {
            curArr.push([dd[0], dd[1], dd[2]]);
            if (this.data.body.prev && prevD && prevD[j]) {
              prevArr.push([prevD[j][0], prevD[j][1], prevD[j][2]]);
            }
          });
        }
      });
    }
    return {
      current: curArr,
      prev: prevArr,
    };
  }

  viewUmsatzKunden(client: any) {
    // localStorage.setItem('cur_umsatzdetails_kunden', JSON.stringify([client]));
    getRouter().replace("/kundenanalyse");
  }

  bwaGoTo(bwaAccountCode: any, linkName: string) {
    var parameters = BwaSummaryTable[bwaAccountCode];
    if (!parameters) {
      return;
    }
    var action = parameters[0];
    var link = parameters[1];

    if (action == "redirect") {
      localStorage.setItem("from", this.id);
      getRouter().push("/" + link);
    } else if (action == "redirect_filter") {
      if (this.storeData.year && this.storeData.client) {
        api
          .getBwaKonto(
            this.storeData.client,
            this.storeData.year,
            bwaAccountCode
          )
          .then((res) => {
            localStorage.setItem("from", this.id);
            localStorage.setItem(
              "buchungssaetze_filter_konto",
              JSON.stringify((res as any).data)
            );
            getRouter().push("/" + link);
          });
      }
    } else if (action == "anchor") {
      const anchor: any = document.getElementById(link);
      anchor.scrollIntoView();
    }
  }

  viewBookingRecordByAccount(accountCode: any) {
    localStorage.setItem(
      "buchungssaetze_filter_konto",
      JSON.stringify([accountCode])
    );
    localStorage.setItem("from", this.id);
    getRouter().push("/buchungssaetze");
  }

  getFormattedNumber(val: any) {
    return getFormattedGermanNum(val);
  }
}
